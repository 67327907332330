export const cookieparser = process.server ? require("cookieparser") : undefined

export const strict = false

export const state = () => ({
  counter: 0
})

export const mutations = {
  increment(state) {
    state.counter++
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    
    let token = null, 
        user = {},
        is_admin = false,
        checkout = {},
        polling = []

    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        token = parsed.token
        user = JSON.parse(parsed.user)
        is_admin = JSON.parse(parsed.is_admin)

        checkout = JSON.parse(parsed.checkout)
        polling = JSON.parse(parsed.polling)
      } catch (err) {
        // No valid cookie found
      }
    }

    commit('member/auth/setToken', token)
    commit('member/auth/setPolling', polling)
    commit('member/auth/setUser', user)
    commit('member/auth/setIsAdmin', is_admin)

    commit('member/auth/setCheckout', checkout)
  }
}
