import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2447d070 = () => interopDefault(import('../pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))
const _06695f2b = () => interopDefault(import('../pages/beranda/index.vue' /* webpackChunkName: "pages/beranda/index" */))
const _c61d676a = () => interopDefault(import('../pages/berita/index.vue' /* webpackChunkName: "pages/berita/index" */))
const _7596db98 = () => interopDefault(import('../pages/display/index.vue' /* webpackChunkName: "pages/display/index" */))
const _2fe0702e = () => interopDefault(import('../pages/galeri/index.vue' /* webpackChunkName: "pages/galeri/index" */))
const _4eee48cd = () => interopDefault(import('../pages/kebijakan-privasi/index.vue' /* webpackChunkName: "pages/kebijakan-privasi/index" */))
const _048e1638 = () => interopDefault(import('../pages/kontak/index.vue' /* webpackChunkName: "pages/kontak/index" */))
const _d766c1a0 = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _52a03722 = () => interopDefault(import('../pages/pengumuman/index.vue' /* webpackChunkName: "pages/pengumuman/index" */))
const _d5bb20f4 = () => interopDefault(import('../pages/potensi/index.vue' /* webpackChunkName: "pages/potensi/index" */))
const _231ef6a0 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _87eeca96 = () => interopDefault(import('../pages/tentang/index.vue' /* webpackChunkName: "pages/tentang/index" */))
const _b8737726 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _c5f5f2a6 = () => interopDefault(import('../pages/visi/index.vue' /* webpackChunkName: "pages/visi/index" */))
const _0c385f4e = () => interopDefault(import('../pages/profil/geografis.vue' /* webpackChunkName: "pages/profil/geografis" */))
const _4ef1f5c4 = () => interopDefault(import('../pages/profil/lambang.vue' /* webpackChunkName: "pages/profil/lambang" */))
const _046839be = () => interopDefault(import('../pages/profil/sejarah.vue' /* webpackChunkName: "pages/profil/sejarah" */))
const _34603e78 = () => interopDefault(import('../pages/profil/visi-misi.vue' /* webpackChunkName: "pages/profil/visi-misi" */))
const _065c0641 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c98aa9fa = () => interopDefault(import('../pages/berita/_slug.vue' /* webpackChunkName: "pages/berita/_slug" */))
const _2e29cee6 = () => interopDefault(import('../pages/galeri/_slug.vue' /* webpackChunkName: "pages/galeri/_slug" */))
const _2c1cec2b = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _560d79b2 = () => interopDefault(import('../pages/pengumuman/_slug.vue' /* webpackChunkName: "pages/pengumuman/_slug" */))
const _d9286384 = () => interopDefault(import('../pages/potensi/_slug.vue' /* webpackChunkName: "pages/potensi/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agenda",
    component: _2447d070,
    name: "agenda"
  }, {
    path: "/beranda",
    component: _06695f2b,
    name: "beranda"
  }, {
    path: "/berita",
    component: _c61d676a,
    name: "berita"
  }, {
    path: "/display",
    component: _7596db98,
    name: "display"
  }, {
    path: "/galeri",
    component: _2fe0702e,
    name: "galeri"
  }, {
    path: "/kebijakan-privasi",
    component: _4eee48cd,
    name: "kebijakan-privasi"
  }, {
    path: "/kontak",
    component: _048e1638,
    name: "kontak"
  }, {
    path: "/layanan",
    component: _d766c1a0,
    name: "layanan"
  }, {
    path: "/pengumuman",
    component: _52a03722,
    name: "pengumuman"
  }, {
    path: "/potensi",
    component: _d5bb20f4,
    name: "potensi"
  }, {
    path: "/profil",
    component: _231ef6a0,
    name: "profil"
  }, {
    path: "/tentang",
    component: _87eeca96,
    name: "tentang"
  }, {
    path: "/video",
    component: _b8737726,
    name: "video"
  }, {
    path: "/visi",
    component: _c5f5f2a6,
    name: "visi"
  }, {
    path: "/profil/geografis",
    component: _0c385f4e,
    name: "profil-geografis"
  }, {
    path: "/profil/lambang",
    component: _4ef1f5c4,
    name: "profil-lambang"
  }, {
    path: "/profil/sejarah",
    component: _046839be,
    name: "profil-sejarah"
  }, {
    path: "/profil/visi-misi",
    component: _34603e78,
    name: "profil-visi-misi"
  }, {
    path: "/",
    component: _065c0641,
    name: "index"
  }, {
    path: "/berita/:slug",
    component: _c98aa9fa,
    name: "berita-slug"
  }, {
    path: "/galeri/:slug",
    component: _2e29cee6,
    name: "galeri-slug"
  }, {
    path: "/page/:slug?",
    component: _2c1cec2b,
    name: "page-slug"
  }, {
    path: "/pengumuman/:slug",
    component: _560d79b2,
    name: "pengumuman-slug"
  }, {
    path: "/potensi/:slug",
    component: _d9286384,
    name: "potensi-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
